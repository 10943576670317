.nav {
  padding: 1rem 4rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: space-between;
  background-color: #1d1d1d;
}

.navbar {
  grid-template-columns: repeat(4, 1fr);
  align-content: center;
  width: fit-content;
}

.logo {
  justify-self: right;
}

.logo img {
  object-fit: contain;
}

.navbar-900 {
  display: none;
}

.nav-link {
  justify-self: center;
  padding: 10px 30px;
  font-size: 26px;
  color: #f1f1f1;
  letter-spacing: 0.5px;
  transition: all 400ms ease;
  font-family: 'monsterrat-bold';
}

.mid-link::before,
.mid-link::after,
.last-link::before {
  content: '';
  position: absolute;
  top: 0;
  height: 100%;
  width: 3px;
  background-color: #cecece;
  border-radius: 3px;
}

.mid-link::before,
.last-link::before {
  left: 0;
}

.mid-link::after {
  right: 0;
}

.nav-link:hover {
  color: #ddb621;
}

@media screen and (max-width: 900px) {
  .nav {
    padding: 1rem 1.5rem;
    display: block;
    grid-template-columns: repeat(1, 1fr);
  }

  .logo {
    justify-self: left;
  }

  .nav img {
    width: 70%;
    height: auto;
  }

  .navbar {
    display: none !important;
  }

  .navbar-900 {
    display: block;
  }

  .ham-menu {
    position: absolute;
    right: 5%;
    top: 3%;
    cursor: pointer;
  }

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(5px);
    z-index: 8;
  }

  .navbar-menu-900 {
    position: absolute;
    top: 0%;
    right: 0;
    width: 0%;
    height: 100%;
    z-index: 10;
    transition: all 250ms ease-out;
  }

  .menu-item-900 {
    position: relative;
    display: grid;
    justify-content: center;
    align-items: center;
    height: 17%;
    width: 100%;
    background-color: #f1f1f1;
    z-index: 12;
    opacity: 0;
  }

  .visible-menu-item-900 {
    opacity: 1;
  }

  .menu-item-900 span {
    color: #333333;
    text-transform: uppercase;
    letter-spacing: 0.6px;
    font-size: 1.5rem;
    font-family: 'monsterrat-extraBold';
  }

  .active-menu-900 {
    width: 65%;
  }
}
