.services-wrap {
  padding: 4rem 6rem 8rem 6rem;
}

.services-header {
  margin-bottom: 4rem;
}

.services-header h1 {
  font-size: 54px;
  text-transform: uppercase;
  line-height: 52px;
  user-select: none;
  color: var(--text-gray);
  margin-bottom: 30px;
}

.services-header h2 {
  font-size: 28px;
  line-height: 30px;
  width: 55%;
  color: #000;
}

.three-in-row {
  grid-template-columns: repeat(2, 1fr);
  column-gap: 50px;
  margin-bottom: 6rem;
}

.container {
  justify-self: center;
  align-self:flex-start;
  padding: 2rem 0.5rem;
}

.container img {
  width: auto;
  height: 250px;
  margin: 0 auto;
  margin-bottom: 10px;
}

.container h3 {
  text-align: center;
  text-transform: uppercase;
  font-size: 24px;
  line-height: 26px;
  margin-bottom: 10px;
  color: var(--text-gray);
}

.container h4 {
  font-size: 18px;
  line-height: 20px;
  margin-bottom: 3px;
  font-family: 'monsterrat-medium';
}

.container ol {
  list-style: disc;
  margin-left: 18px;
  margin-bottom: 5px;
}

.container ol > li {
  font-family: 'monsterrat-medium';
  font-size: 18px;
  line-height: 22px;
}

.container h5 {
  font-size: 20px;
  text-align: center;
  font-family: 'monsterrat-medium';
  user-select: none;
}

.container a {
  border: 2px solid var(--main-yellow);
  background-color: var(--main-yellow);
  width: fit-content;
  margin: 10px auto;
  padding: 7px 40px;
  font-size: 18px;
  text-transform: uppercase;
  font-family: 'monsterrat-Bold';
  color: #1a1a1a;
  cursor: pointer;
  transition: all 0.6s;
}

.container a:hover {
  background-color: #fff;
}

.container ol {
  line-height: 17px;
}

.container li > span {
  font-weight: bold;
  user-select: none;
}

.freeze {
  grid-template-columns: repeat(2, 1fr);
  column-gap: 50px;
}

.freeze {
  margin-bottom: 75px;
}

.freeze img {
  width: 100%;
  height: auto;
}

.freeze-text h3 {
  font-size: 44px;
  line-height: 52px;
  text-transform: uppercase;
  text-align: right;
  color: var(--text-gray);
}

.freeze-text h4 {
  font-size: 18px;
  line-height: 22px;
  width: 90%;
  margin-left: auto;
}

.freeze-text h4 > a {
  color: #000;
  text-decoration: underline;
  font-family: 'monsterrat-medium';
}

.freeze-text ol {
  list-style-type: disc;
}

.freeze-text ol > li {
  margin-left: 20px;
  padding: 2px 0;
  font-size: 17px;
  font-family: 'monsterrat-medium';
  color: #1a1a1a;
}

.hour {
  position: absolute;
  right: 10px;
  top: 40%;
  font-size: 22px;
  line-height: 24px;
  text-align: right;
  font-family: 'monsterrat-medium';
  background-color: #fff;
  padding: 5px;
  border-radius: 10px;
  border: 2px solid var(--main-yellow);
}

@media screen and (max-width: 1200px) {
  .freeze-text h3 {
    text-align: center;
  }

  .freeze-text h4 {
    width: 100%;
    margin: 0 0 20px 0;
  }

  .freeze {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media screen and (max-width: 900px) {
  .services-wrap {
    padding: 4rem 1rem;
  }

  .services-header h1 {
    width: min-content;
  }

  .services-header h2 {
    width: 75%;
  }

  .three-in-row {
    grid-template-columns: repeat(1, 1fr);
    margin-bottom: 3rem;
  }

  .mid-truck {
    transform: rotateY(180deg) !important;
  }

  .freeze h3 {
    padding-bottom: 15px;
    font-size: 38px;
    line-height: 40px;
  }

  .freeze ol {
    padding-bottom: 10px;
  }

  .hour {
    position: relative;
    top: 0;
    right: 0;
    text-align: center;
    border: 0;
  }
}
