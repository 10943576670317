.kontakt {
  padding-top: 4rem;
}

.kontakt-header h1 {
  font-size: 32px;
  text-transform: uppercase;
  user-select: none;
  margin-bottom: 7.5px;
  letter-spacing: 0.5px;
  color: var(--text-gray);
}

.kontakt-header h2 {
  font-size: 15px;
  letter-spacing: 0.2px;
  color: var(--text-gray);
}

.dane {
  width: 80%;
  margin: 5rem auto 4rem auto;
  justify-content: center;
  grid-template-columns: repeat(3, 1fr);
}

.container svg {
  margin-bottom: 5px;
}

.container p {
  font-size: 19px;
  line-height: 26px;
}

.container p > a {
  color: #000;
  border-bottom: 1px solid #000;
  font-family: 'monsterrat-regular';
}

.mails p {
  font-family: 'monsterrat-bold';
  display: grid;
  width: fit-content;
  margin: 0 auto 15px auto;
}

.map-button {
  cursor: pointer;
  width: fit-content;
  margin: 10px auto 0 auto;
  color: #000;
}

.map-button span {
  font-weight: bold;
  font-family: 'monsterrat-light';
}

.map-button svg {
  justify-self: center;
  margin-top: 5px;
  transition: all 600ms;
}

.map-button:hover svg {
  transform: translateY(10px) scale(1.15);
}

.form-wrap {
  background-color: #f0f0f0;
  padding: 5rem 0 6rem 0;
}

.form-wrap h3 {
  text-align: center;
  font-weight: bold;
  font-size: 26px;
  user-select: none;
  padding: 0rem 0 4rem 0;
}

.form-wrap form {
  width: 900px;
  justify-self: center;
}

.form-wrap label {
  margin-bottom: 5px;
  font-size: 16px;
  color: #1a1a1a;
  user-select: none;
}

.error {
  color: rgb(216, 22, 22) !important;
}

.form-wrap input {
  padding: 8px 20px;
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: bold;
  height: 60px;
}

.rows-wrap {
  grid-template-columns: repeat(2, 1fr);
}

.second-row {
  justify-self: right;
}

.second-row input,
.first-row input {
  width: 350px;
}

.form-wrap textarea {
  resize: none;
  padding: 16px 20px;
  font-weight: bold;
  font-size: 18px;
}

.required {
  margin-top: 15px;
  width: fit-content;
  margin-left: auto;
  font-size: 14px;
  font-family: 'monsterrat-light';
  font-weight: bold;
}

.buttons-wrap {
  margin: 0 auto;
}

.buttons-wrap button {
  cursor: pointer;
  background-color: var(--main-yellow);
  color: #1a1a1a;
  font-family: 'monsterrat-bold';
  letter-spacing: 0.5px;
  padding: 18px 80px;
  margin: 20px 10px 0 10px;
  font-size: 22px;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;
  transition: all 400ms;
}

.buttons-wrap button:hover {
  filter: brightness(1.1);
}

.mess-after-form {
  padding-top: 2rem;
  text-align: center;
}

.mess-after-form p {
  margin-top: 5px;
  font-weight: bold;
  letter-spacing: 0.2px;
  font-size: 18px;
}

@media screen and (max-width: 900px) {
  .dane,
  .rows-wrap {
    grid-template-columns: repeat(1, 1fr);
  }

  .form-wrap form {
    width: fit-content;
    padding: 0 1.5rem;
  }

  .second-row {
    justify-self: unset;
  }

  .form-wrap input,
  .form-wrap textarea {
    width: 100%;
  }

  .buttons-wrap button {
    width: 85%;
  }
}
