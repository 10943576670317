.map,
.google-map {
  width: 100%;
  height: 65vmin;
}

@media screen and (max-width: 800px) {
  .map {
    padding-top: 4rem;
    margin-bottom: 4rem;
  }

  .map,
  .google-map {
    width: 100%;
    height: 70vmin;
  }
}
