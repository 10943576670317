.footer {
  width: 100%;
  background-color: #f0f0f0;
  padding: 1.5rem 8rem;
}

.footer hr {
  background-color: #1a1a1a;
  height: 1px;
  border: 0;
}

.boxes-wrap {
  grid-template-columns: repeat(3, 1fr);
  padding: 2rem 0;
}

.footer-box h3 {
  width: fit-content;
  position: relative;
  user-select: none;
  font-size: 20px;
  margin-bottom: 30px;
}

.footer-box h3::before {
  content: '';
  position: absolute;
  left: 0;
  bottom: -10px;
  width: 50%;
  height: 2px;
  background-color: #1a1a1a;
}

.container {
  margin-bottom: 5px;
}

.container svg {
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-70%);
  transform: translateY(-70%);
}

.icon-container {
  width: 32px;
}

.footer-box p,
.footer-box a {
  margin-bottom: 8px;
  font-size: 17px;
  line-height: 18px;
  color: #1a1a1a;
  transition: all 400ms;
  width: fit-content;
  font-family: 'monsterrat-regular';
}

.footer-box a:hover {
  color: #9c9c9c;
}

.copyright {
  font-size: 16px;
  letter-spacing: 0.5px;
  justify-content: space-between;
}

.copyright p {
  display: flex;
  user-select: none;
  color: #000;
  font-weight: bold;
}

.copyright svg {
  transform: translateY(-1px);
  margin-right: 3px;
}

.copyright a {
  color: #000;
  transition: all 400ms;
  font-family: 'monsterrat-regular';
  text-align: center;
}

.copyright a:hover {
  color: #9c9c9c;
}

@media screen and (max-width: 900px) {
  .footer {
    padding: 2rem 1.5rem;
  }

  .boxes-wrap {
    padding: 1rem 0 2rem 0;
  }

  .footer-box {
    margin-top: 1rem;
  }

  .footer-box a,
  .footer-box p {
    margin-bottom: 12px;
    font-size: 18px;
  }

  .container svg {
    transform: translateY(-80%);
  }

  .boxes-wrap {
    grid-template-columns: repeat(1, 1fr);
  }

  .copyright a {
    width: min-content;
    text-align: center;
    border: 0;
  }
}
