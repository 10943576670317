.kontakt {
  padding-top: 4rem;
}

.kontakt-header h1 {
  font-size: 32px;
  text-transform: uppercase;
  user-select: none;
  margin-bottom: 7.5px;
  letter-spacing: 0.5px;
  color: var(--text-gray);
  margin-bottom: 4rem;
}

.wymagania-wrap {
  width: 70%;
  margin: 0 auto 4rem auto;
}

.wymagania-wrap h2 {
  color: #9c9c9c;
  font-size: 28px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  text-align: center;
  margin-bottom: 35px;
}

.wymagania-wrap h2 > span {
  font-size: 18px;
  text-transform: none;
  font-family: 'monsterrat-bold';
}

.wymagania-wrap h3 {
  font-size: 22px;
  color: var(--text-gray);
  margin-bottom: 7.5px;
}

.wymagania-wrap ol {
  margin-bottom: 30px;
  list-style: disc;
}

.wymagania-wrap ol > li {
  font-size: 18px;
  font-family: 'monsterrat-medium';
  line-height: 20px;
  letter-spacing: 0.6px;
  margin-left: 20px;
}

.form-wrap {
  background-color: #f0f0f0;
  padding: 5rem 0 6rem 0;
}

.form-header h3 {
  font-size: 26px;
  margin-bottom: 1rem;
}

.form-header h4 {
  width: 65%;
  margin: 10px auto;
  font-size: 18px;
  line-height: 20px;
}

.form-header p {
  font-weight: bold;
  line-height: 18px;
  margin: 5px 0;
}

.form-header p > a {
  color: #000;
  text-decoration: underline;
  font-family: 'monsterrat-regular';
}

.form-wrap form {
  width: 900px;
  justify-self: center;
  margin-top: 3rem;
}

.form-wrap label {
  margin-bottom: 5px;
  font-size: 16px;
  color: #1a1a1a;
  user-select: none;
}

.error {
  color: rgb(216, 22, 22) !important;
}

.form-wrap input {
  padding: 8px 20px;
  margin-bottom: 20px;
  font-size: 18px;
  height: 60px;
  font-weight: bold;
}

.rows-wrap {
  grid-template-columns: repeat(2, 1fr);
}

.second-row {
  justify-self: right;
}

.second-row input,
.first-row input {
  width: 350px;
}

.form-wrap textarea {
  resize: none;
  padding: 16px 20px;
  font-weight: bold;
  font-size: 18px;
}

.required {
  width: fit-content;
  justify-self: right;
  font-size: 14px;
  font-family: 'monsterrat-light';
  font-weight: bold;
}

.required-cv {
  margin-top: 15px;
  grid-template-columns: repeat(2, 1fr);
  justify-content: space-between;
}

.cv-input input {
  margin-top: 5px;
  padding: 0;
}

.buttons-wrap {
  width: fit-content;
  margin: 0 auto;
}

.buttons-wrap button {
  cursor: pointer;
  background-color: var(--main-yellow);
  color: #1a1a1a;
  font-family: 'monsterrat-bold';
  letter-spacing: 0.5px;
  padding: 18px 80px;
  margin: 20px 10px 0 10px;
  font-size: 22px;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;
  transition: all 0.4s;
}

.buttons-wrap button:hover {
  filter: brightness(1.1);
}

.mess-after-form {
  padding-top: 2rem;
  text-align: center;
}

.mess-after-form p {
  margin-top: 5px;
  font-weight: bold;
  letter-spacing: 0.2px;
  font-size: 18px;
}

@media screen and (max-width: 900px) {
  .wymagania-wrap {
    width: 85%;
  }

  .form-header h4 {
    width: 80%;
  }

  .form-wrap form {
    width: fit-content;
    padding: 0 1.5rem;
  }

  .rows-wrap {
    grid-template-columns: repeat(1, 1fr);
  }

  .second-row {
    justify-self: unset;
  }

  .form-wrap input,
  .form-wrap textarea {
    width: 100%;
  }

  .buttons-wrap button {
    width: 85%;
  }
}
