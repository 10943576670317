.oferta {
  padding: 4rem 4rem 6rem 4rem;
}

.oferta h2 {
  text-align: center;
  font-weight: bold;
  color: var(--text-gray);
  font-size: 50px;
  margin-bottom: 50px;
}

.first-row {
  margin-bottom: 50px;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 35px;
}

.container img {
  width: 100%;
  height: 650px;
  object-fit: cover;
}

.container-text {
  position: absolute;
  bottom: 15px;
  right: 15px;
  background-color: #fff;
  color: var(--text-gray);
  width: 55%;
  padding: 1.5rem 1rem;
  border: 2px solid var(--main-yellow);
}

.container-text h3 {
  margin-bottom: 10px;
  text-transform: uppercase;
  font-size: 22px;
  line-height: 24px;
  margin-bottom: 25px;
  text-align: center;
}

.container-text a {
  display: block;
  background-color: var(--main-yellow);
  padding: 8px 13px;
  font-size: 16px;
  width: fit-content;
  margin: 0 auto;
  font-family: 'monsterrat-bold';
  color: #000;
  border: 2px solid transparent;
  transition: all 600ms;
}

.container-text a > svg {
  transform: translateY(3px);
}

.container-text a:hover {
  background-color: #fff;
  border: 2px solid var(--main-yellow);
}

.about-us {
  padding: 0 6rem 6rem 6rem;
}

.about-us h2 {
  text-align: center;
  color: var(--text-gray);
  font-size: 50px;
  margin-bottom: 10px;
  user-select: none;
}

.about-us h3 {
  text-align: center;
  margin: 0 auto;
  width: 68%;
  font-size: 23px;
  line-height: 30px;
  margin-bottom: 75px;
}

.about-us span {
  line-height: 30px;
  font-family: 'monsterrat-extrabold';
  letter-spacing: 0.4px;
}

.zalety-wrap {
  grid-template-columns: repeat(4, 1fr);
  column-gap: 1rem;
  margin-bottom: 6rem;
}

.zaleta {
  padding: 0 0.5rem;
}

.zaleta svg,
.zaleta img {
  margin-bottom: 15px;
}

.zaleta h4 {
  font-size: 32px;
  text-transform: uppercase;
  letter-spacing: 0.4px;
  margin-bottom: 8px;
  color: var(--text-gray);
}

.buttons-wrap a {
  background-color: var(--main-yellow);
  padding: 25px 40px;
  font-size: 20px;
  text-transform: uppercase;
  color: #000;
  margin: 0 15px;
  transition: all 600ms;
  font-family: 'monsterrat-extrabold';
}

.buttons-wrap a:hover {
  filter: brightness(1.1);
}

@media screen and (max-width: 1500px) {
  .container-text {
    width: 65%;
  }

  .about-us h3 {
    width: 90%;
  }
}

@media screen and (max-width: 1400px) {
  .container-text {
    width: 75%;
  }

  .about-us h3 {
    width: 100%;
  }
}

@media screen and (max-width: 1200px) {
  .first-row {
    grid-template-columns: repeat(1, 1fr);
    margin-bottom: 0;
  }

  .second-row,
  .zalety-wrap {
    grid-template-columns: repeat(1, 1fr);
  }

  .container {
    margin-bottom: 15px;
  }

  .container img {
    object-fit: contain;
    object-position: 30%;
    height: auto;
  }

  .container-text {
    position: relative;
    padding: 0.5rem 0.5rem 1.5rem 0.5rem;
    width: 100%;
    left: 0px;
    bottom: 0px;
    border: 0;
  }

  .about-us {
    padding: 0 3rem 6rem 3rem;
  }
}

@media screen and (max-width: 950px) {
  .oferta {
    padding: 4rem 1.5rem;
  }

  .oferta h2 {
    margin-bottom: 25px;
  }

  .zalety-wrap {
    margin-bottom: 2rem;
  }

  .container-text h3 {
    font-size: 20px;
    line-height: 22px;
  }

  .about-us {
    padding: 0 0.5rem 2rem 0.5rem;
  }

  .about-us h3 {
    width: 95%;
    letter-spacing: 0.4px;
    margin-left: 0;
    text-align: left;
    font-size: 22px;
    line-height: 24px;
    word-wrap: break-word;
  }

  .zaleta,
  .buttons-wrap a {
    margin-bottom: 25px;
  }

  .buttons-wrap {
    display: grid;
  }

  .hour-wrap {
    position: relative;
    border: 0;
    top: 0;
    right: 0;
  }
}
