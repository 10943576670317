.blog-wrap {
  padding: 2rem 6rem;
  background-color: #f0f0f0;
}

.blog-wrap h3 {
  margin-bottom: 2rem;
  font-size: 50px;
  text-align: center;
  color: #333333;
  letter-spacing: 0.2px;
  user-select: none;
}

.blog {
  grid-template-columns: repeat(4, 1fr);
  column-gap: 35px;
  padding-bottom: 45px;
}

.post-container {
  width: 100%;
  min-height: 290px;
  height: fit-content;
  border: 2px solid var(--main-yellow);
  background-color: #fff;
  padding: 0.5rem 0.5rem 1rem 0.5rem;
}

.post-container img {
  width: 100%;
  height: 180.953px;
  object-fit: contain;
  aspect-ratio: auto 404 / 180.953;
}

.post-container span {
  margin-top: 5px;
  text-align: right;
  font-size: 14px;
  color: #000;
  user-select: none;
  font-weight: bold;
  font-family: 'monsterrat-light';
}

.post-container span > svg {
  transform: translateY(3px);
  margin-right: 3px;
}

.post-container h4 {
  margin: 15px 0 10px 0;
  text-align: center;
  font-size: 18px;
  letter-spacing: 0.5px;
  color: #333;
}

.post-container p {
  color: #000;
}

.post-container button {
  border: 2px solid var(--main-yellow);
  background-color: var(--main-yellow);
  width: 60%;
  margin: 15px auto 0 auto;
  padding: 5px 0;
  font-family: 'monsterrat-extrabold';
  cursor: pointer;
  transition: all 400ms;
}

.post-container button:hover {
  background-color: #fff;
}

.show-more-button {
  background-color: var(--main-yellow);
  padding: 23px 40px;
  border: 2px solid var(--main-yellow);
  font-size: 20px;
  font-family: 'monsterrat-extrabold';
  color: #000;
  text-transform: uppercase;
  justify-self: center;
  cursor: pointer;
  transition: all 400ms;
}

.show-more-button:hover {
  background-color: transparent;
}

@media screen and (max-width: 1400px) {
  .blog {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (max-width: 1200px) {
  .blog {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 900px) {
  .blog-wrap {
    padding: 2rem 0.5rem;
    background-color: #f0f0f0;
  }

  .blog {
    grid-template-columns: repeat(1, 1fr);
    row-gap: 25px;
  }

  .post-container img {
    height: auto;
  }
}
