.galeria {
  padding: 4rem 0 6rem 0;
}

.galeria-text {
  padding-bottom: 5rem;
  color: var(--text-gray);
}

.galeria-text h1 {
  font-size: 32px;
  text-transform: uppercase;
  user-select: none;
  margin-bottom: 7.5px;
  letter-spacing: 0.5px;
}

.galeria-text h2 {
  font-size: 15px;
}

@media screen and (max-width: 900px) {
  .galeria {
    padding: 4rem 0;
  }

  .galeria-text {
    width: 80%;
    margin: 0 auto;
  }

  .galeria-text h1 {
    font-size: 34px;
  }

  .galeria-text h2 {
    font-size: 17px;
  }
}
