.my-masonry-grid {
  justify-self: center;
  margin-left: -10px;
  width: 80%;
}

.my-masonry-grid_column {
  padding-left: 10px;
}

.my-masonry-grid_column > div {
  margin-bottom: 20px;
  overflow: hidden;
  box-shadow: rgb(38, 57, 77) 0px 20px 30px -15px;
}

.image {
  border-radius: 10px;
  cursor: pointer;
}

.image-text {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 30%;
  background: linear-gradient(
    0deg,
    rgba(26, 26, 26, 1) 0%,
    rgba(26, 26, 26, 0.9) 35%,
    rgba(26, 26, 26, 0.8) 55%,
    rgba(26, 26, 26, 0.6) 75%,
    rgba(26, 26, 26, 0.4) 85%,
    rgba(26, 26, 26, 0.2) 95%,
    rgba(26, 26, 26, 0) 100%
  );
  z-index: 5;
}

.text-wrap {
  position: absolute;
  bottom: 15%;
  left: 15px;
}

.text-wrap h2 {
  color: var(--main-yellow);
  font-size: 20px;
}

.text-wrap h3 {
  color: #f1f1f1;
  font-size: 18px;
  letter-spacing: 0.5px;
}

@media screen and (max-width: 900px) {
  .my-masonry-grid {
    width: 95%;
  }

  .my-masonry-grid_column > div {
    margin-bottom: 15px;
  }
}
