.header img {
  width: 100%;
  max-height: 75vh;
  object-fit: cover;
  aspect-ratio: auto 1240 / 697.5;
}

.header h1 {
  position: absolute;
  top: 30%;
  left: 3%;
  z-index: 5;
  color: #f1f1f1;
  font-size: 48px;
  line-height: 52px;
  user-select: none;
  text-transform: uppercase;
  letter-spacing: 0.2px;
}

.header span {
  color: var(--main-yellow);
  font-family: 'monsterrat-extraBold';
}

.header::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  height: calc(100% - 2px);
  width: 30%;
  background: linear-gradient(
    90deg,
    rgba(26, 26, 26, 1) 0%,
    rgba(26, 26, 26, 0.9) 5%,
    rgba(26, 26, 26, 0.8) 35%,
    rgba(26, 26, 26, 0.6) 55%,
    rgba(26, 26, 26, 0.4) 75%,
    rgba(26, 26, 26, 0.2) 95%,
    rgba(26, 26, 26, 0) 100%
  );
  z-index: 3;
}

@media screen and (max-width: 900px) {
  .header h1 {
    width: min-content;
    top: 27%;
  }

  .header img {
    height: 75vh;
    object-position: 100%;
  }

  .header::before {
    width: 60%;
  }
}
